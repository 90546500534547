import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PreloaderComponent from '../../common/preloaderComponent'
import BaseBlockComponent from './baseBlockComponent'

import { getCookie } from '../../../utils/cookies'
import api from '../../../utils/api'
import { links } from '../../../config'

class GitComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: true,
      message: null,
      github: null,
      gitlab: null,
      totalContributions: false,
    }

    this.icon = ['fab', 'git-alt']
    this.color = 'git-color'
    this.title = 'Git'
    this.link = links.github
  }

  render() {
    if (this.state.isLoading) {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          <PreloaderComponent />
        </BaseBlockComponent>
      )
    } else {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          {!this.state.success && this.state.message ? (
            <Alert variant="danger">{this.state.message}</Alert>
          ) : (
            <div style={{ height: '100%' }}>
              <div className="mini-header">Last year contributions</div>
              {!this.state.github.success && this.state.github.message ? (
                <Alert variant="danger">{this.state.github.message}</Alert>
              ) : (
                <div>
                  <FontAwesomeIcon icon={['fab', 'github']} className="purple" />{' '}
                  <a href={links.github} target="_blank" rel="noopener noreferrer">
                    Github:
                  </a>{' '}
                  <span className="green font-weight-bold">{this.state.github.totalContributions}</span>
                </div>
              )}
              {!this.state.gitlab.success && this.state.gitlab.message ? (
                <Alert variant="danger">{this.state.gitlab.message}</Alert>
              ) : (
                <div>
                  <FontAwesomeIcon icon={['fab', 'gitlab']} className="orange" />{' '}
                  <a href={links.gitlab} target="_blank" rel="noopener noreferrer">
                    Gitlab:
                  </a>{' '}
                  <span className="green font-weight-bold">{this.state.gitlab.totalContributions}</span>
                </div>
              )}
              {this.state.totalContributions ? (
                <div>
                  <FontAwesomeIcon icon={['fab', 'git']} className="git-color" />{' '}
                  <span>Total: <span className="font-weight-bold green">{this.state.totalContributions}</span></span>
                </div>
              ) : null}
            </div>
          )}
        </BaseBlockComponent>
      )
    }
  }

  async componentDidMount() {
    try {
      let gitData = await api.get('/dashboard/git', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
      })

      gitData = gitData.data

      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: gitData.success,
          message: gitData.message,
          github: gitData.github,
          gitlab: gitData.gitlab,
          totalContributions: gitData.totalContributions,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default GitComponent
