import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class MainComponent extends Component {
  render() {
    return (
      <div className="container-fluid auth">
        <div className="d-flex justify-content-center">
          <div className='jumbotron smalljumbo'>
            <Link to="/">
              <img alt="Logo" className="mx-auto d-block" src={process.env.PUBLIC_URL + '/img/icons/icon.png'} />
            </Link>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

export default MainComponent
