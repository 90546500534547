import React, { Component } from 'react'
import { Alert, ProgressBar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import prettyBytes from 'pretty-bytes'
import Numeral from 'numeral'
import 'numeral/locales/fi'

import PreloaderComponent from '../../common/preloaderComponent'
import BaseBlockComponent from './baseBlockComponent'

import { getCookie } from '../../../utils/cookies'
import api from '../../../utils/api'
import { links } from '../../../config'

Numeral.locale('fi')

class SysComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: true,
      message: null,
      sysInfo: null,
    }

    this.icon = 'server'
    this.color = 'lightgreen'
    this.title = 'Sysinfo'
    this.link = links.sysinfo
  }

  render() {
    function bytes(bytes) {
      return prettyBytes(parseInt(bytes) * 1000)
    }

    function percentage(num, per) {
      return Numeral((parseInt(num) * 100) / parseInt(per)).format('0')
    }

    function MachineComponent(props) {
      let machine = props.machine
      let color
      let icon
      switch (machine.os.toLowerCase()) {
        case 'arch linux':
          color = 'archlinux-color'
          break
        case 'freebsd':
          color = 'freebsd-color'
          break
        default:
          color = 'foreground'
      }
      switch (machine.hostname.toLowerCase()) {
        case 'r5':
          icon = 'desktop'
          break
        case 'server':
          icon = 'server'
          break
        default:
          icon = 'laptop'
      }
      let diskPercentage = percentage(machine.disk_used, machine.disk_total)
      let memPercentage = percentage(machine.mem_used, machine.mem_total)
      return (
        <div className="mb-2">
          <div className="mini-header">
            <FontAwesomeIcon icon={icon} className="pink" /> {machine.hostname}
          </div>
          <div className="ml-2">
            <div className="mb-1">
              <span className={color}>{machine.os_icon}</span> {machine.os} ({machine.kernel})
            </div>
            <div className="mb-1">
              <FontAwesomeIcon icon="stopwatch" className="pink" /> {machine.uptime}{' '}
              <FontAwesomeIcon icon="tachometer-alt" className="pink" /> {machine.load}
            </div>
            <div className="mb-1">
              <FontAwesomeIcon icon="hdd" className="pink" /> {bytes(machine.disk_used)} / {bytes(machine.disk_total)}
              <ProgressBar
                style={{ marginLeft: '5px', float: 'right', width: '40%' }}
                now={diskPercentage}
                label={`${diskPercentage}%`}
              />
            </div>
            <div className="mb-1">
              <FontAwesomeIcon icon="memory" className="pink" /> {bytes(machine.mem_used)} / {bytes(machine.mem_total)}
              <ProgressBar
                style={{ marginLeft: '5px', float: 'right', width: '40%' }}
                now={memPercentage}
                label={`${memPercentage}%`}
              />
            </div>
          </div>
        </div>
      )
    }

    if (this.state.isLoading) {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          <PreloaderComponent />
        </BaseBlockComponent>
      )
    } else {
      let storagePercentage = percentage(this.state.sysInfo.storage.disk_used, this.state.sysInfo.storage.disk_total)
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          {!this.state.success && this.state.message ? (
            <Alert variant="danger">{this.state.message}</Alert>
          ) : (
            <div style={{ height: '100%' }}>
              {this.state.sysInfo.r5.type === 'host_down' ? (
                <div className="mb-2">
                  <div className="mini-header">r5</div>
                  <div className="ml-2">
                    <div className="mb-1">
                      <span>Host down</span>
                    </div>
                  </div>
                </div>
              ) : (
                <MachineComponent machine={this.state.sysInfo.r5} />
              )}
              <MachineComponent machine={this.state.sysInfo.server} />
              <div>
                <div className="mini-header">
                  <FontAwesomeIcon icon="hdd" className="pink" /> /mnt/storage
                </div>
                <div className="ml-2">
                  <span>
                    {bytes(this.state.sysInfo.storage.disk_used)} / {bytes(this.state.sysInfo.storage.disk_total) + ' '}
                  </span>{' '}
                  <ProgressBar
                    style={{ marginLeft: '5px', float: 'right', width: '40%' }}
                    now={storagePercentage}
                    label={`${storagePercentage}%`}
                  />
                </div>
              </div>
            </div>
          )}
        </BaseBlockComponent>
      )
    }
  }

  async componentDidMount() {
    try {
      let sysData = await api.get('/dashboard/sys', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
      })

      sysData = sysData.data

      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: sysData.success,
          message: sysData.message,
          sysInfo: sysData.sysInfo,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default SysComponent
