import React, { Component } from 'react'

import { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import BitcoinComponent from '../blocks/bitcoinComponent'
import GitComponent from '../blocks/gitComponent'
import SysComponent from '../blocks/sysComponent'

const ResponsiveGridLayout = WidthProvider(Responsive)

class RandomTabComponent extends Component {
  render() {
    const layouts = {
      xl: [
        { i: 'bitcoin', x: 0, y: 0, w: 2, h: 4 },
        { i: 'git', x: 0, y: 1, w: 1, h: 2 },
        { i: 'sys', x: 2, y: 0, w: 1, h: 6 },
      ],
      lg: [
        { i: 'bitcoin', x: 0, y: 0, w: 2, h: 3 },
        { i: 'sys', x: 2, y: 0, w: 1, h: 6 },
        { i: 'git', x: 0, y: 1, w: 1, h: 3 },
      ],
      sm: [
        { i: 'bitcoin', x: 0, y: 0, w: 1, h: 4 },
        { i: 'git', x: 0, y: 1, w: 1, h: 2 },
        { i: 'sys', x: 0, y: 2, w: 1, h: 6 },
      ],
    }

    return (
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ xl: 1500, lg: 800, sm: 0 }}
        cols={{ xl: 4, lg: 3, sm: 1 }}
        margin={[15, 15]}
        rowHeight={70}
        draggableHandle={'.drag-handle'}
      >
        <div key="bitcoin">
          <BitcoinComponent />
        </div>
        <div key="git">
          <GitComponent />
        </div>
        <div key="sys">
          <SysComponent />
        </div>
      </ResponsiveGridLayout>
    )
  }
}

export default RandomTabComponent
