import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import Moment from 'moment'

import PreloaderComponent from '../../common/preloaderComponent'
import BaseBlockComponent from './baseBlockComponent'

import { getCookie } from '../../../utils/cookies'
import api from '../../../utils/api'
import { links } from '../../../config'

class YleComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: true,
      message: null,
      news: null,
    }

    this.icon = process.env.PUBLIC_URL + '/img/block-icons/yle.png'
    this.color = 'blue'
    this.title = 'Yle'
    this.link = links.yle
  }

  render() {
    if (this.state.isLoading) {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link} customIcon>
          <PreloaderComponent />
        </BaseBlockComponent>
      )
    } else {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link} customIcon>
          {!this.state.success && this.state.message ? (
            <Alert variant="danger">{this.state.message}</Alert>
          ) : (
            <div style={{ height: '100%' }}>
              <ul>
                {this.state.news.map((item, i) => (
                  <li key={i} className="news-item">
                    <span className="green">{Moment(item.pubDate).format('D.M. HH:MM')}: </span>
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </BaseBlockComponent>
      )
    }
  }

  async componentDidMount() {
    try {
      let yleData = await api.get('/dashboard/yle', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
      })

      yleData = yleData.data

      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: yleData.success,
          message: yleData.message,
          news: yleData.news,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default YleComponent
