import React, { Component } from 'react'
import { Alert, Button } from 'react-bootstrap'
import Numeral from 'numeral'
import 'numeral/locales/fi'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import Moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PreloaderComponent from '../../common/preloaderComponent'
import BaseBlockComponent from './baseBlockComponent'

import { getCookie, setCookie } from '../../../utils/cookies'
import api from '../../../utils/api'
import { links } from '../../../config'
import styles from '../../../styles/index.scss'

Numeral.locale('fi')

class BankComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: null,
      message: null,
      redirectUrl: null,
      accountData: null,
      accountSuccess: false,
      history: null,
    }

    this.icon = 'piggy-bank'
    this.color = 'purple'
    this.title = 'Bank'
    this.link = links.bank
  }

  handleLogout = async event => {
    event.preventDefault()
    setCookie('viia-access-token', getCookie('viia-access-token'), 0)
    setCookie('viia-login-token', getCookie('viia-login-token'), 0)
    window.location.reload(false)
  };

  render() {
    function CustomTooltip({ payload, label, active }) {
      if (active) {
        return (
          <div className="custom-tooltip">
            <span className="label">
              Date: {Moment(label).format('D.M.YYYY HH:mm')}
              <br />
              Price: {payload[0].value} €
            </span>
          </div>
        )
      }
      return null
    }
    if (this.state.isLoading) {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          <PreloaderComponent />
        </BaseBlockComponent>
      )
    } else {
      const renderDate = payload => {
        return Moment(payload).format('D.M.')
      }

      const renderPrice = payload => {
        return Math.round(payload) + ' €'
      }
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          {!this.state.success && this.state.message ? <Alert variant="danger">{this.state.message}</Alert> : null}
          {this.state.accountSuccess ? (
            <div style={{ height: '100%' }}>
              {this.state.accountData.map((account, i) => {
                return (
                  <div key={i}>
                    <div>
                      <FontAwesomeIcon icon="user" className="pink" /> {account.accountProvider.name}: {account.name}
                    </div>
                    <div>
                      <FontAwesomeIcon icon="coins" className="pink" />{' '}
                      {Numeral(account.availableBalance).format('$0,0.00')} (
                      {Numeral(account.bookedBalance).format('$0,0.00')})
                    </div>
                  </div>
                )
              })}
              <ResponsiveContainer width="100%" height="80%">
                <LineChart data={this.state.history}>
                  <Line type="monotone" dataKey="balance" stroke={styles.pink} />
                  <CartesianGrid stroke={styles.comment} />
                  <XAxis dataKey="date" stroke={styles.purple} tickFormatter={renderDate} />
                  <YAxis
                    domain={['dataMin', 'dataMax']}
                    interval="preserveStartEnd"
                    stroke={styles.purple}
                    tickFormatter={renderPrice}
                  />
                  <Tooltip content={<CustomTooltip />} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <Button variant="primary" href={this.state.redirectUrl}>
              Auth
            </Button>
          )}
        </BaseBlockComponent>
      )
    }
  }

  async componentDidMount() {
    let viiaAccessToken = getCookie('viia-access-token')
    if (viiaAccessToken) {
      try {
        let bankAccountData = await api.post(
          '/dashboard/bank/account',
          {
            viiaAccessToken: viiaAccessToken,
          },
          {
            headers: {
              Authorization: getCookie('dashboard-token'),
              'Content-Type': 'application/json',
            },
          }
        )
        bankAccountData = bankAccountData.data
        let history = []
        history.push({
          balance: bankAccountData.history[0].accounts[0].available,
          date: bankAccountData.history[0].date,
        })
        for (let i = 1; i < bankAccountData.history.length; i++) {
          if (
            new Date(bankAccountData.history[i].date).getDate() !==
            new Date(bankAccountData.history[i - 1].date).getDate()
          ) {
            history.push({
              balance: bankAccountData.history[i].accounts[0].available,
              date: bankAccountData.history[i].date,
            })
          }
        }
        this.setState({
          ...this.state,
          ...{
            isLoading: false,
            accountSuccess: bankAccountData.success,
            success: bankAccountData.success,
            message: bankAccountData.message,
            accountData: bankAccountData.accountData,
            history: history,
          },
        })
      } catch (e) {
        setCookie('viia-access-token', getCookie('viia-access-token'), 0)
        setCookie('viia-login-token', getCookie('viia-login-token'), 0)
        try {
          let bankAuthData = await api.get('/dashboard/bank/auth', {
            headers: {
              Authorization: getCookie('dashboard-token'),
              'Content-Type': 'application/json',
            },
          })

          bankAuthData = bankAuthData.data

          this.setState({
            ...this.state,
            ...{
              isLoading: false,
              success: bankAuthData.success,
              message: bankAuthData.message,
              redirectUrl: bankAuthData.redirectUrl,
            },
          })
        } catch (e) {
          this.setState({
            ...this.state,
            ...{
              isLoading: false,
              success: false,
              message: 'Request failed',
            },
          })
        }
      }
    } else {
      try {
        let bankAuthData = await api.get('/dashboard/bank/auth', {
          headers: {
            Authorization: getCookie('dashboard-token'),
            'Content-Type': 'application/json',
          },
        })

        bankAuthData = bankAuthData.data

        this.setState({
          ...this.state,
          ...{
            isLoading: false,
            success: bankAuthData.success,
            message: bankAuthData.message,
            redirectUrl: bankAuthData.redirectUrl,
          },
        })
      } catch (e) {
        this.setState({
          ...this.state,
          ...{
            isLoading: false,
            success: false,
            message: 'Request failed',
          },
        })
      }
    }
  }
}

export default BankComponent
