import React from 'react'
import ReactDOM from 'react-dom'

import App from './container/App'
import './styles/index.scss'

import './fonts/retro.ttf'
import './fonts/pixel.ttf'
import './fonts/hvd.ttf'
import './fonts/gohu.ttf'
import './fonts/profont.ttf'
import './fonts/proggy.ttf'
import './fonts/shure.ttf'
import './fonts/terminess.ttf'
import './fonts/blex.ttf'
import './fonts/3270.ttf'
import './fonts/3x5.ttf'
import './fonts/arcade.ttf'

import { version } from '../package.json'

ReactDOM.render(<App />, document.getElementById('root'))


console.log('%c ╔╦╗╔═╗╔═╗╦ ╦╔╗ ╔═╗╔═╗╦═╗╔╦╗ \n' +
              '  ║║╠═╣╚═╗╠═╣╠╩╗║ ║╠═╣╠╦╝ ║║ \n' +
              ' ═╩╝╩ ╩╚═╝╩ ╩╚═╝╚═╝╩ ╩╩╚══╩╝ \n' +
              '%c ═══════════════════════════ \n' +
              '%c           v. ' + version + '               ',
'background: #000b1e; color: #d300c4; font-size: 20px; text-shadow: 3px 3px 10px #711c91;',
'background: #000b1e; color: #1afe49; font-size: 20px; text-shadow: 1px 1px 3px #00ff9f;',
'background: #000b1e; color: #d300c4; font-size: 17px; text-shadow: 1px 1px 3px #711c91;',
)
