import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PreloaderComponent from '../../common/preloaderComponent'
import BaseBlockComponent from './baseBlockComponent'

import { getCookie } from '../../../utils/cookies'
import api from '../../../utils/api'
import { links } from '../../../config'

class WikiComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: true,
      message: null,
      news: null,
      trivia: null,
    }

    this.icon = ['fab', 'wikipedia-w']
    this.color = 'white'
    this.title = 'Wikipedia'
    this.link = links.wikipedia
  }

  render() {
    if (this.state.isLoading) {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          <PreloaderComponent />
        </BaseBlockComponent>
      )
    } else {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          {!this.state.success && this.state.message ? (
            <Alert variant="danger">{this.state.message}</Alert>
          ) : (
            <div style={{ height: '100%' }}>
              {!this.state.news.success && this.state.news.message ? (
                <Alert variant="danger">{this.state.news.message}</Alert>
              ) : (
                <div className="wiki-content">
                  <div className="mini-header">
                    <FontAwesomeIcon icon="newspaper" /> <span className="foreground">News</span>
                  </div>
                  <ul>
                    {this.state.news.content.map((item, i) => (
                      <li key={i} className="news-item">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {!this.state.trivia.success && this.state.trivia.message ? (
                <Alert variant="danger">{this.state.trivia.message}</Alert>
              ) : (
                <div className="wiki-content">
                  <div className="mini-header">
                    <FontAwesomeIcon icon="info" /> <span className="foreground">Did you know?</span>
                  </div>
                  <ul>
                    {this.state.trivia.content.map((item, i) => (
                      <li key={i} className="news-item">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </BaseBlockComponent>
      )
    }
  }

  async componentDidMount() {
    try {
      let wikiData = await api.get('/dashboard/wiki', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
      })

      wikiData = wikiData.data

      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: wikiData.success,
          message: wikiData.message,
          news: wikiData.news,
          trivia: wikiData.trivia,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default WikiComponent
