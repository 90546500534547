import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Moment from 'moment'
import { Alert, Tabs, Tab } from 'react-bootstrap'

import MainTabComponent from './tabs/mainTabComponent'
import NewsTabComponent from './tabs/newsTabComponent'
import RandomTabComponent from './tabs/randomTabComponent'

import HeaderComponent from '../common/headerComponent'
import PreloaderComponent from '../common/preloaderComponent'

import { getCookie } from '../../utils/cookies'
import api from '../../utils/api'

Moment.locale('fi')

class DashboardComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: null,
      message: null,
      date: null,
      user: null,
      activeTab: 'main',
    }
  }

  render() {
    if (this.state.isLoading === true) {
      return <PreloaderComponent size="big" />
    }

    if (!this.state.user) {
      return <Redirect to="/login" />
    }

    let setActiveTab = tab => {
      this.setState({
        activeTab: tab,
      })
    }

    return (
      <div>
        <HeaderComponent />
        <div className="container-fluid" style={{ marginBottom: '2rem' }}>
          {this.state.message ? (
            <Alert variant={!this.state.success ? 'danger' : 'success'}>{this.state.message}</Alert>
          ) : null}
          <p className="secondary-font db-text" style={{ float: 'left' }}>
            Hello{' '}
            <span className="green" style={{ textTransform: 'capitalize' }}>
              {this.state.user.firstName} {this.state.user.lastName}
            </span>
          </p>
          <p className="text-right secondary-font db-text">
            <span className="cyan display-full">{Moment(this.state.date).format('dddd')}</span>{' '}
            <span className="cyan display-min">{Moment(this.state.date).format('ddd')}</span>{' '}
            <span className="cyan display-full">{Moment(this.state.date).format('D.M.YYYY')}</span>{' '}
            <span className="cyan display-min">{Moment(this.state.date).format('D.M.YY')}</span>{' '}
          </p>
          <Tabs activeKey={this.state.activeTab} onSelect={k => setActiveTab(k)} id="dashboard-tabs">
            <Tab eventKey="main" title="Main">
              {this.state.activeTab === 'main' ? <MainTabComponent /> : null}
            </Tab>
            <Tab eventKey="news" title="News">
              {this.state.activeTab === 'news' ? <NewsTabComponent /> : null}
            </Tab>
            <Tab eventKey="random" title="Random">
              {this.state.activeTab === 'random' ? <RandomTabComponent /> : null}
            </Tab>
          </Tabs>
        </div>
      </div>
    )
  }

  async componentDidMount() {
    try {
      let dashboardData = await api.get('/dashboard', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
        params: {
          userId: this.state.id,
        },
      })

      dashboardData = dashboardData.data

      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: dashboardData.success,
          message: dashboardData.message,
          date: dashboardData.date,
          user: dashboardData.user,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default DashboardComponent
