import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class BaseBlockComponent extends Component {
  render() {
    return (
      <Card style={{ minWidth: this.props.minWidth }}>
        <Card.Header>
          {this.props.customIcon ? (
            <img alt="Block icon" style={{ width: '10px', height: '10px' }} src={this.props.icon} />
          ) : (
            <FontAwesomeIcon icon={this.props.icon} className={this.props.color} />
          )}{' '}
          <a href={this.props.link} target="_blank" rel="noopener noreferrer">
            <span className="pink secondary-font">{this.props.title}</span>
          </a>
          <FontAwesomeIcon icon="grip-lines" className="drag-handle pink" style={{ float: 'right' }} />
        </Card.Header>

        <Card.Body>{this.props.children}</Card.Body>
      </Card>
    )
  }
}

export default BaseBlockComponent
