import React, { Component } from 'react'
import { Alert, Button } from 'react-bootstrap'
import Moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PreloaderComponent from '../../common/preloaderComponent'
import BaseBlockComponent from './baseBlockComponent'

import { getCookie } from '../../../utils/cookies'
import api from '../../../utils/api'
import { links } from '../../../config'

class CalendarComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: true,
      message: null,
      secondaryActive: false,
      primary: {
        isAuth: false,
        redirectUrl: null,
        events: null,
      },
      secondary: {
        isAuth: false,
        redirectUrl: null,
        events: null,
      },
    }

    this.icon = 'calendar-week'
    this.color = 'green'
    this.title = 'Calendar'
    this.link = links.calendar
  }

  orgSwitchCase(org) {
    if (org.email.includes('lvsbrokers')) {
      return { cal: 'LVS', class: 'lvs' }
    }
    switch (org.email) {
      case 'maks.turtiainen@gmail.com':
        return { cal: 'Maks', class: 'primary' }
      case 'asteriskiry@gmail.com':
        return { cal: 'Ast', class: 'asteriski' }
      case 'bd1f2akrrmkj6tpp2irc116rck@group.calendar.google.com':
        return { cal: 'Ast Hal', class: 'asteriski-hallitus' }
      case 'airje31klgiuisbmb6hcmcb38c@group.calendar.google.com':
        return { cal: 'Ast Y-toim', class: 'asteriski-y-toimikunta' }
      case 'sadl2ir7a647ge5kb7th2f5mijl09f36@import.calendar.google.com':
        return { cal: 'UTU', class: 'utu' }
      default:
        return { cal: 'No name', class: 'no-name' }
    }
  }

  render() {
    function EventComponent(props) {
      const event = props.event
      const orgSwitchCase = props.orgSwitchCase
      return (
        <div className={props.i !== 0 ? 'cal-event middle-event' : 'cal-event'}>
          <div className="cal-date">
            <FontAwesomeIcon icon="calendar-day" className="pink" />{' '}
            {Moment(event.start.date || event.start.dateTime).format('ddd DD.MM.')}
          </div>
          <div className="cal-summary">
            <a href={event.htmlLink} className={orgSwitchCase(event.organizer).class} target="_blank" rel="noopener noreferrer">
              {event.summary}
            </a>
          </div>
          {event.start.dateTime ? (
            <div className="cal-time">
              <FontAwesomeIcon icon="clock" className="pink" /> {Moment(event.start.dateTime).format('HH:mm')}
              {event.end.dateTime ? (
                <span> – {Moment(event.end.dateTime).format('HH:mm')}</span>
              ) : null}
            </div>
          ) : null}
        </div>
      )
    }
    if (this.state.isLoading) {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          <PreloaderComponent />
        </BaseBlockComponent>
      )
    } else {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          {!this.state.success && this.state.message ? <Alert variant="danger">{this.state.message}</Alert> : null}
          {this.state.primary.isAuth ? (
            <div>
              {this.state.primary.events.map((event, i) => {
                return (
                  <div key={i} className="mb-2">
                    <EventComponent event={event} orgSwitchCase={this.orgSwitchCase} i={i} />
                  </div>
                )
              })}
            </div>
          ) : (
            <Button className="mb-2" href={this.state.primary.redirectUrl}>Auth</Button>
          )}
          {this.state.secondaryActive ? (
            <div>
              {this.state.secondary.isAuth ? (
                <div>
                  <div className="small-header">LVS</div>
                  {this.state.secondary.events.map((event, i) => {
                    return (
                      <div key={i}>
                        <EventComponent event={event} orgSwitchCase={this.orgSwitchCase} i={i} />
                      </div>
                    )
                  })}
                </div>
              ) : (
                <Button href={this.state.secondary.redirectUrl}>Auth (LVS)</Button>
              )}
            </div>
          ) : null}
        </BaseBlockComponent>
      )
    }
  }

  async componentDidMount() {
    try {
      let eventsData = await api.get('/dashboard/calendar/events', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
      })
      eventsData = eventsData.data
      if (eventsData.primary.action === 'auth') {
        this.setState({
          ...this.state,
          ...{
            isLoading: false,
            success: eventsData.success,
            message: eventsData.message,
            primary: {
              isAuth: false,
              success: eventsData.primary.success,
              message: eventsData.primary.message,
              redirectUrl: eventsData.primary.redirectUrl,
            },
          },
        })
      } else {
        this.setState({
          ...this.state,
          ...{
            isLoading: false,
            success: eventsData.success,
            message: eventsData.message,
            primary: {
              isAuth: true,
              success: eventsData.primary.success,
              message: eventsData.primary.message,
              events: eventsData.primary.events.slice(0, 3),
            },
          },
        })
      }

      if (typeof eventsData.secondary !== 'undefined') {
        if (eventsData.secondary.action === 'auth') {
          this.setState({
            ...this.state,
            ...{
              isLoading: false,
              success: eventsData.success,
              message: eventsData.message,
              secondaryActive: true,
              secondary: {
                isAuth: false,
                success: eventsData.secondary.success,
                message: eventsData.secondary.message,
                redirectUrl: eventsData.secondary.redirectUrl,
              },
            },
          })
        } else {
          this.setState({
            ...this.state,
            ...{
              isLoading: false,
              success: eventsData.success,
              message: eventsData.message,
              secondaryActive: true,
              secondary: {
                isAuth: true,
                success: eventsData.secondary.success,
                message: eventsData.secondary.message,
                events: eventsData.secondary.events.slice(0, 2),
              },
            },
          })
        }
      }
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default CalendarComponent
