import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import Moment from 'moment'

import PreloaderComponent from '../../common/preloaderComponent'
import BaseBlockComponent from './baseBlockComponent'

import { getCookie } from '../../../utils/cookies'
import api from '../../../utils/api'
import { links } from '../../../config'

class WeatherComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: true,
      message: null,
      weather: null,
      forecast1: null,
      forecast2: null,
    }

    this.icon = 'cloud'
    this.color = 'foreground'
    this.title = 'Weather'
    this.link = links.weather
  }

  render() {
    function iconUrl(icon) {
      return `https://openweathermap.org/img/wn/${icon}.png`
    }
    if (this.state.isLoading) {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          <PreloaderComponent />
        </BaseBlockComponent>
      )
    } else {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          {!this.state.success && this.state.message ? (
            <Alert variant="danger">{this.state.message}</Alert>
          ) : (
            <div>
              <p>
                {this.state.weather.location} now <img alt="Weather" src={iconUrl(this.state.weather.skyIcon)} />{' '}
                <span className="green">{this.state.weather.temperature} °C</span>
                <br />
                {Moment(this.state.forecast1.date).format('ddd HH:mm')}{' '}
                <img alt="Weather forecast" src={iconUrl(this.state.forecast1.skyIcon)} />{' '}
                <span className="green">{this.state.forecast1.temperature} °C</span>
                <br />
                {Moment(this.state.forecast2.date).format('ddd HH:mm')}{' '}
                <img alt="Weather forecast" src={iconUrl(this.state.forecast2.skyIcon)} />{' '}
                <span className="green">{this.state.forecast2.temperature} °C</span>
              </p>
            </div>
          )}
        </BaseBlockComponent>
      )
    }
  }

  async componentDidMount() {
    try {
      let weatherData = await api.get('/dashboard/weather', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
      })

      weatherData = weatherData.data

      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: weatherData.success,
          message: weatherData.message,
          weather: weatherData.weather,
          forecast1: weatherData.forecast1,
          forecast2: weatherData.forecast2,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default WeatherComponent
