import React, { Component } from 'react'
import { Navbar } from 'react-bootstrap'
import { Nav } from 'react-bootstrap'
import Clock from 'react-live-clock'
import { links } from '../../config'

class HeaderComponent extends Component {
  render() {
    return (
      <div className="header-container">
        <Navbar expand="md" className="justify-content-between">
          <Navbar.Brand className="display-full" href="/">
            Dash<span className="purple">Board</span>
          </Navbar.Brand>
          <Navbar.Brand className="display-min" href="/">
            Dash<span className="purple">B</span>
          </Navbar.Brand>
          <a href={links.clock} target="_blank" rel="noopener noreferrer">
            <Clock className="green nav-clock" format={'HH:mm:ss'} ticking={true} />
          </a>
          <Nav>
            <Nav.Link href="/logout">Logout</Nav.Link>
          </Nav>
        </Navbar>
      </div>
    )
  }
}

export default HeaderComponent
