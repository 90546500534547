import React, { Component } from 'react'

import { Alert, Button, Row, Col, Accordion, Modal, Form } from 'react-bootstrap'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PreloaderComponent from '../../common/preloaderComponent'
import BaseBlockComponent from './baseBlockComponent'

import { getCookie } from '../../../utils/cookies'
import api from '../../../utils/api'
import { links } from '../../../config'

class NotesComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      getLoading: false,
      id: getCookie('id'),
      success: true,
      message: null,
      defaultNoteName: null,
      defaultNotePath: null,
      noteList: null,
      noteName: null,
      notePath: null,
      noteContent: null,
      newNoteName: '',
      newNoteContent: '',
      addNoteMode: false,
      warning: false,
      dialogMessage: '',
    }

    this.icon = 'clipboard'
    this.color = 'red'
    this.title = 'Notes'
    this.link = links.notes
  }

  render() {
    let modalClose = () => this.setState({ warning: false })

    let handleNoteChange = event => {
      this.setState({
        noteContent: event.target.value,
      })
    }

    let handleNewNoteChange = event => {
      this.setState({
        newNoteContent: event.target.value,
      })
    }

    let handleNewNoteNameChange = event => {
      this.setState({
        newNoteName: event.target.value,
      })
    }

    let toggleAddNoteMode = () => {
      this.setState({
        addNoteMode: !this.state.addNoteMode,
      })
    }

    let onHandleRemove = () => {
      this.setState({
        warning: true,
        dialogMessage: `Sure want to remove file ${this.state.noteName}?`,
      })
    }

    let handleRemoveNote = async event => {
      event.preventDefault()
      this.setState({ isLoading: true })
      try {
        const data = {
          filePath: this.state.notePath,
        }

        let response = await api.post('/dashboard/notes/remove', data, {
          headers: {
            Authorization: getCookie('dashboard-token'),
            'Content-Type': 'application/json',
          },
        })
        response = response.data

        let notesData = await api.get('/dashboard/notes', {
          headers: {
            Authorization: getCookie('dashboard-token'),
            'Content-Type': 'application/json',
          },
        })
        notesData = notesData.data

        this.setState({
          ...this.state,
          ...{
            isLoading: false,
            success: response.success,
            message: response.message,
            noteName: notesData.defaultNoteName,
            notePath: notesData.defaultNotePath,
            noteContent: notesData.defaultNoteContent,
            noteList: notesData.noteList,
            warning: false,
            dialogMessage: '',
          },
        })
      } catch (e) {
        this.setState({
          ...this.state,
          ...{
            isLoading: false,
            success: false,
            message: 'Request failed',
          },
        })
      }
    }

    let handleUpdateNote = async event => {
      event.preventDefault()
      this.setState({ isLoading: true })
      try {
        const data = {
          filePath: this.state.notePath,
          fileContent: this.state.noteContent,
        }

        let response = await api.post('/dashboard/notes', data, {
          headers: {
            Authorization: getCookie('dashboard-token'),
            'Content-Type': 'application/json',
          },
        })
        response = response.data
        this.setState({
          ...this.state,
          ...{
            isLoading: false,
            success: response.success,
            message: response.message,
            noteName: response.newFileName,
            notePath: response.newFilePath,
            noteContent: response.newFileContent,
          },
        })
      } catch (e) {
        this.setState({
          ...this.state,
          ...{
            isLoading: false,
            success: false,
            message: 'Request failed',
          },
        })
      }
    }

    let handleAddNote = async event => {
      event.preventDefault()
      this.setState({ isLoading: true })
      try {
        const data = {
          fileName: this.state.newNoteName,
          fileContent: this.state.newNoteContent,
        }

        let response = await api.post('/dashboard/notes', data, {
          headers: {
            Authorization: getCookie('dashboard-token'),
            'Content-Type': 'application/json',
          },
        })
        response = response.data

        let notesData = await api.get('/dashboard/notes', {
          headers: {
            Authorization: getCookie('dashboard-token'),
            'Content-Type': 'application/json',
          },
        })
        notesData = notesData.data

        this.setState({
          ...this.state,
          ...{
            isLoading: false,
            success: response.success,
            message: response.message,
            noteName: response.newFileName,
            notePath: response.newFilePath,
            noteContent: response.newFileContent,
            noteList: notesData.noteList,
            newNoteName: '',
            newNoteContent: '',
            addNoteMode: false,
          },
        })
      } catch (e) {
        this.setState({
          ...this.state,
          ...{
            isLoading: false,
            success: false,
            message: 'Request failed',
          },
        })
      }
    }

    let handleGetNote = async (event, fileName, filePath) => {
      event.preventDefault()
      this.setState({ getLoading: true })
      try {
        let note = await api.get('/dashboard/notes', {
          headers: {
            Authorization: getCookie('dashboard-token'),
            'Content-Type': 'application/json',
          },
          params: {
            fileName: fileName,
            filePath: filePath,
          },
        })
        note = note.data
        this.setState({
          ...this.state,
          ...{
            getLoading: false,
            success: note.success,
            message: note.message,
            noteName: note.fileName,
            notePath: note.filePath,
            noteContent: note.fileContent,
            addNoteMode: false,
          },
        })
      } catch (e) {
        this.setState({
          ...this.state,
          ...{
            getLoading: false,
            success: false,
            message: 'Request failed',
            addNoteMode: false,
          },
        })
      }
    }

    function CustomToggle({ children, eventKey }) {
      const decoratedOnClick = useAccordionToggle(eventKey, () => {})
      return (
        <div onClick={decoratedOnClick}>
          <FontAwesomeIcon icon="folder" className="pink" /> <span className="file">{children}</span>
        </div>
      )
    }

    function File(props) {
      const fileName = props.file.fileName
      const filePath = props.file.filePath
      return (
        <div>
          <div role="button" onClick={e => handleGetNote(e, fileName, filePath)}>
            <FontAwesomeIcon icon="file" className="pink" />{' '}
            <span className={props.currentFile === fileName && !props.addNoteMode ? 'green file' : 'foreground file'}>
              {fileName}
            </span>
          </div>
        </div>
      )
    }

    function FileBrowser(props) {
      let files = props.files
      return (
        <div>
          {files.map(file => {
            if (file.type === 'd') {
              return (
                <Accordion
                  key={file.fileName}
                  defaultActiveKey={props.currentFilePath.includes('archive') ? 'archive' : null}
                >
                  <div>
                    <span>
                      <CustomToggle eventKey={file.fileName}>{file.fileName}</CustomToggle>
                    </span>
                    <Accordion.Collapse eventKey={file.fileName}>
                      <div className="ml-3">
                        <FileBrowser
                          key={file.fileName}
                          files={file.sub}
                          currentFile={props.currentFile}
                          currentFilePath={props.currentFilePath}
                          addNoteMode={props.addNoteMode}
                        />
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              )
            } else {
              return (
                <File key={file.fileName} file={file} currentFile={props.currentFile} addNoteMode={props.addNoteMode} />
              )
            }
          })}
        </div>
      )
    }

    if (this.state.isLoading) {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          <PreloaderComponent />
        </BaseBlockComponent>
      )
    } else {
      return (
        <div style={{ height: '100%' }}>
          <Modal
            show={this.state.warning}
            onHide={modalClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{this.state.dialogMessage}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleRemoveNote}>
                Yes
              </Button>
              <Button onClick={modalClose}>No</Button>
            </Modal.Footer>
          </Modal>
          <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
            {this.state.message ? (
              <Alert variant={this.state.success ? 'success' : 'danger'}>{this.state.message}</Alert>
            ) : null}
            <Row>
              <Col xs={4}>
                <FileBrowser
                  files={this.state.noteList}
                  currentFile={this.state.noteName}
                  currentFilePath={this.state.notePath}
                  addNoteMode={this.state.addNoteMode}
                />
              </Col>
              <Col>
                {this.state.getLoading ? (
                  <PreloaderComponent />
                ) : (
                  <div>
                    <div className="form-group">
                      {this.state.addNoteMode ? (
                        <div>
                          <Row>
                            <Col xs={8}>
                              <Form.Control
                                name="newNoteName"
                                id="newNoteName"
                                className="small-form"
                                defaultValue=""
                                placeholder="File name"
                                required
                                onChange={handleNewNoteNameChange}
                              />
                            </Col>
                            <Col>
                              <div role="button" className="float-right" onClick={toggleAddNoteMode}>
                                <FontAwesomeIcon icon="window-close" className="action-icon" />
                              </div>
                            </Col>
                          </Row>
                          <textarea
                            className="form-control notes-textarea"
                            id="noteEditor"
                            rows="15"
                            value={this.state.newNoteContent}
                            onChange={handleNewNoteChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <Row>
                            <Col xs={8}>
                              <label htmlFor="noteEditor">{this.state.noteName}</label>
                            </Col>
                            <Col>
                              <div role="button" className="float-right" onClick={toggleAddNoteMode}>
                                <FontAwesomeIcon icon="plus-square" className="action-icon" />
                              </div>
                            </Col>
                          </Row>
                          <textarea
                            className="form-control notes-textarea"
                            id="noteEditor"
                            rows="15"
                            value={this.state.noteContent}
                            onChange={handleNoteChange}
                          />
                        </div>
                      )}
                    </div>
                    <div className="text-center">
                      {this.state.addNoteMode ? (
                        <div>
                          <Button onClick={handleAddNote}>Add</Button>
                        </div>
                      ) : (
                        <div>
                          <Button onClick={handleUpdateNote}>Save</Button>
                          <Button variant="danger" onClick={onHandleRemove.bind(this)}>
                            Rm
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </BaseBlockComponent>
        </div>
      )
    }
  }

  async componentDidMount() {
    try {
      let notesData = await api.get('/dashboard/notes', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
      })
      notesData = notesData.data

      let defaultNote = await api.get('/dashboard/notes', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
        params: {
          fileName: notesData.defaultNoteName,
          filePath: notesData.defaultNotePath,
        },
      })
      defaultNote = defaultNote.data

      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          isAuth: false,
          success: notesData.success,
          message: notesData.message,
          defaultNoteName: notesData.defaultNoteName,
          defaultNotePath: notesData.defaultNotePath,
          noteList: notesData.noteList,
          noteName: defaultNote.fileName,
          notePath: defaultNote.filePath,
          noteContent: defaultNote.fileContent,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default NotesComponent
