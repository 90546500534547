import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import Moment from 'moment'
import _ from 'lodash'

import PreloaderComponent from '../../common/preloaderComponent'
import BaseBlockComponent from './baseBlockComponent'

import { getCookie } from '../../../utils/cookies'
import api from '../../../utils/api'
import styles from '../../../styles/index.scss'
import { links } from '../../../config'

class BitcoinComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: true,
      message: null,
      currentPrice: null,
      currentPriceEUR: null,
      priceHistory: null,
    }

    this.icon = ['fab', 'bitcoin']
    this.color = 'orange'
    this.title = 'Bitcoin'
    this.link = links.bitcoin
  }

  render() {
    function CustomTooltip({ payload, label, active }) {
      if (active) {
        return (
          <div className="custom-tooltip">
            <span className="label">
              Date: {Moment(label).format('D.M.YYYY HH:mm')}
              <br />
              Price: {payload[0].value} $
            </span>
          </div>
        )
      }
      return null
    }
    if (this.state.isLoading) {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          <PreloaderComponent />
        </BaseBlockComponent>
      )
    } else {
      const renderDate = payload => {
        return Moment(payload).format('D.M. HH:mm')
      }

      const renderPrice = payload => {
        return Math.round(payload) + ' $'
      }
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          {!this.state.success && this.state.message ? (
            <Alert variant="danger">{this.state.message}</Alert>
          ) : (
            <div style={{ height: '100%' }}>
              <p>
                Current price:{' '}
                <b className="green">
                  {this.state.currentPrice} $ ({this.state.currentPriceEUR} €)
                </b>
              </p>
              <ResponsiveContainer width="100%" height="80%">
                <LineChart data={this.state.priceHistory}>
                  <Line type="monotone" dataKey="price" stroke={styles.pink} />
                  <CartesianGrid stroke={styles.comment} />
                  <XAxis dataKey="timestamp" stroke={styles.purple} tickFormatter={renderDate} />
                  <YAxis
                    domain={['dataMin', 'dataMax']}
                    interval="preserveStartEnd"
                    stroke={styles.purple}
                    tickFormatter={renderPrice}
                  />
                  <Tooltip content={<CustomTooltip />} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </BaseBlockComponent>
      )
    }
  }

  async componentDidMount() {
    try {
      let bitcoinData = await api.get('/dashboard/bitcoin', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
      })

      bitcoinData = bitcoinData.data
      bitcoinData.priceHistory = _.takeRight(bitcoinData.priceHistory, 200)
      let priceHistory = []
      let delta = 10
      for (let i = 0; i < bitcoinData.priceHistory.length; i = i + delta) {
        priceHistory.push(bitcoinData.priceHistory[i])
      }

      let currencyExchange = await api.get('/misc/currency', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
        params: {
          from: 'USD',
          to: 'EUR',
          value: bitcoinData.currentPrice,
        },
      })

      const currentPriceEUR = Math.round((currencyExchange.data.value + Number.EPSILON) * 100) / 100

      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: bitcoinData.success,
          message: bitcoinData.message,
          currentPrice: bitcoinData.currentPrice,
          currentPriceEUR: currentPriceEUR,
          priceHistory: priceHistory,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default BitcoinComponent
