import React, { Component } from 'react'

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import PrivateRoute from './privateRoute'

import LoginComponent from '../components/authentication/loginComponent'
import LogoutComponent from '../components/authentication/logoutComponent'
import DashboardComponent from '../components/dashboard/dashboardComponent'
import NotFoundComponent from '../components/notFoundComponent'
import ViiaCallbackComponent from '../components/callbacks/viiaCallbackComponent'
import GoogleOAuth2CallbackComponent from '../components/callbacks/googleOAuth2CallbackComponent'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faTimes,
  faSignInAlt,
  faExclamationTriangle,
  faPiggyBank,
  faCalendarWeek,
  faCloud,
  faWeight,
  faClipboard,
  faFile,
  faFilePdf,
  faFolderOpen,
  faFolder,
  faImage,
  faEdit,
  faTrash,
  faSpinner,
  faPlusSquare,
  faPlus,
  faWindowClose,
  faGripLines,
  faClock,
  faCalendarDay,
  faEuroSign,
  faMoneyBillWave,
  faCoins,
  faUser,
  faEnvelope,
  faEnvelopeOpen,
  faInbox,
  faNewspaper,
  faInfo,
  faServer,
  faUpload,
  faStopwatch,
  faHdd,
  faMemory,
  faMicrochip,
  faLaptop,
  faDesktop,
  faThermometer,
  faTachometerAlt,
} from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(
  faCheck,
  faTimes,
  faSignInAlt,
  faExclamationTriangle,
  fab,
  faPiggyBank,
  faCalendarWeek,
  faCloud,
  faWeight,
  faClipboard,
  faFile,
  faFilePdf,
  faFolderOpen,
  faFolder,
  faImage,
  faEdit,
  faTrash,
  faSpinner,
  faPlusSquare,
  faPlus,
  faWindowClose,
  faGripLines,
  faClock,
  faCalendarDay,
  faEuroSign,
  faMoneyBillWave,
  faCoins,
  faUser,
  faEnvelope,
  faEnvelopeOpen,
  faInbox,
  faNewspaper,
  faInfo,
  faServer,
  faUpload,
  faStopwatch,
  faHdd,
  faMemory,
  faMicrochip,
  faLaptop,
  faDesktop,
  faThermometer,
  faTachometerAlt
)

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PrivateRoute path="/" exact={true} component={DashboardComponent} />
          <PrivateRoute path="/logout" component={LogoutComponent} />
          <PrivateRoute path="/viiaCallback" component={ViiaCallbackComponent} />
          <PrivateRoute path="/googleOAuth2Callback" component={GoogleOAuth2CallbackComponent} />
          <Route path="/login" exact={true} component={LoginComponent} />
          <Route component={NotFoundComponent} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App
