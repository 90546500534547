import React from 'react'

import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import MainComponent from '../common/mainComponent'

const LoginView = ({ success, message, handleLogin, handleInputChange }) => (
  <MainComponent>
    <h3 className="text-center pink">Dashboard</h3>
    {message ? <Alert variant={!success ? 'danger' : 'success'}>{message}</Alert> : null}
    <Form onSubmit={handleLogin} className="login-form">
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control type="text" name="email" onChange={handleInputChange} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" name="password" onChange={handleInputChange} />
      </Form.Group>

      <div className="text-center">
        <Button type="submit">Login</Button>
      </div>
    </Form>
  </MainComponent>
)

export default LoginView
