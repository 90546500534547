import React, { Component } from 'react'

import { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import WikiComponent from '../blocks/wikiComponent'
import HNComponent from '../blocks/hnComponent'
import YleComponent from '../blocks/yleComponent'

const ResponsiveGridLayout = WidthProvider(Responsive)

class NewsTabComponent extends Component {
  render() {
    const layouts = {
      xl: [
        { i: 'wiki', x: 0, y: 0, w: 2, h: 6 },
        { i: 'hn', x: 2, y: 0, w: 1, h: 6 },
        { i: 'yle', x: 3, y: 0, w: 1, h: 10 },
      ],
      lg: [
        { i: 'wiki', x: 0, y: 0, w: 2, h: 6 },
        { i: 'hn', x: 3, y: 0, w: 1, h: 7 },
        { i: 'yle', x: 0, y: 1, w: 2, h: 8 },
      ],
      sm: [
        { i: 'wiki', x: 0, y: 0, w: 1, h: 9 },
        { i: 'hn', x: 0, y: 1, w: 1, h: 6 },
        { i: 'yle', x: 0, y: 2, w: 1, h: 12 },
      ],
    }

    return (
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ xl: 1500, lg: 800, sm: 0 }}
        cols={{ xl: 4, lg: 3, sm: 1 }}
        margin={[15, 15]}
        rowHeight={70}
        draggableHandle={'.drag-handle'}
      >
        <div key="wiki">
          <WikiComponent />
        </div>
        <div key="hn">
          <HNComponent />
        </div>
        <div key="yle">
          <YleComponent />
        </div>
      </ResponsiveGridLayout>
    )
  }
}

export default NewsTabComponent
