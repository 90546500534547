import React, { Component } from 'react'

import { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import BankComponent from '../blocks/bankComponent'
import CalendarComponent from '../blocks/calendarComponent'
import WeatherComponent from '../blocks/weatherComponent'
import WeightComponent from '../blocks/weightComponent'
import NotesComponent from '../blocks/notesComponent'
import EmailComponent from '../blocks/emailComponent'

const ResponsiveGridLayout = WidthProvider(Responsive)

class MainTabComponent extends Component {
  render() {
    const layouts = {
      xl: [
        { i: 'bank', x: 0, y: 0, w: 1, h: 3 },
        { i: 'email', x: 1, y: 0, w: 2, h: 6 },
        { i: 'weather', x: 4, y: 0, w: 1, h: 3 },
        { i: 'calendar', x: 0, y: 1, w: 1, h: 7 },
        { i: 'notes', x: 1, y: 1, w: 2, h: 6 },
        { i: 'weight', x: 3, y: 1, w: 1, h: 6 },
      ],
      lg: [
        { i: 'calendar', x: 0, y: 0, w: 1, h: 7 },
        { i: 'bank', x: 1, y: 0, w: 1, h: 3 },
        { i: 'weather', x: 2, y: 0, w: 1, h: 3 },
        { i: 'notes', x: 0, y: 2, w: 1.5, h: 6 },
        { i: 'weight', x: 1.5, y: 2, w: 1.5, h: 6 },
        { i: 'email', x: 0, y: 3, w: 3, h: 6 },
      ],
      sm: [
        { i: 'bank', x: 0, y: 0, w: 1, h: 3 },
        { i: 'calendar', x: 0, y: 1, w: 1, h: 7 },
        { i: 'weather', x: 0, y: 2, w: 1, h: 3 },
        { i: 'email', x: 0, y: 3, w: 1, h: 10 },
        { i: 'notes', x: 0, y: 4, w: 1, h: 6 },
        { i: 'weight', x: 0, y: 5, w: 1, h: 6 },
      ],
    }

    return (
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ xl: 1500, lg: 800, sm: 0 }}
        cols={{ xl: 4, lg: 3, sm: 1 }}
        margin={[15, 15]}
        rowHeight={70}
        draggableHandle={'.drag-handle'}
      >
        <div key="bank">
          <BankComponent />
        </div>
        <div key="calendar">
          <CalendarComponent />
        </div>
        <div key="weather">
          <WeatherComponent />
        </div>
        <div key="email">
          <EmailComponent />
        </div>
        <div key="notes">
          <NotesComponent />
        </div>
        <div key="weight">
          <WeightComponent />
        </div>
      </ResponsiveGridLayout>
    )
  }
}

export default MainTabComponent
