import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'

import PreloaderComponent from '../common/preloaderComponent'

import { getCookie, setCookie } from '../../utils/cookies'
import api from '../../utils/api'

class ViiaCallbackComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: null,
      message: null,
      viiaCode: null,
      viiaConsentId: null,
      viiaAccessToken: null,
      viiaLoginToken: null,
    }
  }

  render() {
    if (this.state.isLoading === true) {
      return <PreloaderComponent />
    }
    return <Redirect to="/" />
  }

  async componentDidMount() {
    const values = queryString.parse(this.props.location.search)

    this.setState({
      ...this.state,
      ...{
        viiaCode: values['code'],
        viiaConsentId: values['consentId'],
      },
    })

    try {
      let params = {
        viiaCode: values['code'],
        viiaConsentId: values['consentId'],
      }
      let bankTokenData = await api.get('/dashboard/bank/token', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
        params: params,
      })

      bankTokenData = bankTokenData.data

      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: bankTokenData.success,
          message: bankTokenData.message,
          viiaAccessToken: bankTokenData.viiaAccessToken,
          viiaLoginToken: bankTokenData.viiaLoginToken,
        },
      })
      if (this.state.success) {
        setCookie('viia-access-token', this.state.viiaAccessToken, 3600)
        setCookie('viia-login-token', this.state.viiaLoginToken, 3600)
      }
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default ViiaCallbackComponent
