import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'

import PreloaderComponent from '../common/preloaderComponent'

import { getCookie } from '../../utils/cookies'
import api from '../../utils/api'

class GoogleOAuth2CallbackComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: null,
      message: null,
      account: null,
      googleCode: null,
    }
  }

  render() {
    if (this.state.isLoading === true) {
      return <PreloaderComponent />
    }
    return <Redirect to="/" />
  }

  async componentDidMount() {
    const values = queryString.parse(this.props.location.search)

    this.setState({
      ...this.state,
      ...{
        account: values['account'],
        googleCode: values['code'],
      },
    })

    try {
      let params = {
        account: values['account'],
        googleCode: values['code'],
      }
      let googleTokenData = await api.get('/dashboard/calendar/token', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
        params: params,
      })

      googleTokenData = googleTokenData.data

      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: googleTokenData.success,
          message: googleTokenData.message,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default GoogleOAuth2CallbackComponent
