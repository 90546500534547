import React, { Component } from 'react'
import { Alert, Button, Form, Row, Col } from 'react-bootstrap'
import Moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import fi from 'date-fns/locale/fi'

import PreloaderComponent from '../../common/preloaderComponent'
import BaseBlockComponent from './baseBlockComponent'
import styles from '../../../styles/index.scss'

import { getCookie } from '../../../utils/cookies'
import api from '../../../utils/api'
import { links } from '../../../config'

registerLocale('fi', fi)

class WeightComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: true,
      message: null,
      weight: null,
      date: new Date(),
      weightHistory: null,
      addLoading: false,
    }

    this.icon = 'weight'
    this.color = 'yellow'
    this.title = 'Weight'
    this.link = links.weight
  }

  render() {
    let handleInputChange = event => {
      const target = event.target
      const value = target.type === 'checkbox' ? target.checked : target.value
      const name = target.name

      this.setState({
        [name]: value,
      })
    }

    let handleDateChange = date => {
      this.setState({
        date: date,
      })
    }

    let handleAddWeight = async event => {
      this.setState({ addLoading: true })
      event.preventDefault()
      const data = {
        weight: this.state.weight,
        date: this.state.date,
      }

      try {
        const response = await api.post('/dashboard/weight', data, {
          headers: {
            Authorization: getCookie('dashboard-token'),
            'Content-Type': 'application/json',
          },
        })
        const historyResponse = await api.get('/dashboard/weight', {
          headers: {
            Authorization: getCookie('dashboard-token'),
            'Content-Type': 'application/json',
          },
        })
        this.setState({
          ...this.state,
          ...{
            addLoading: false,
            success: response.data.success,
            message: response.data.message,
            weightHistory: historyResponse.data.weightHistory,
          },
        })
      } catch (e) {
        this.setState({
          ...this.state,
          ...{
            addLoading: false,
            success: false,
            message: 'Request failed',
          },
        })
      }
    }

    function CustomTooltip({ payload, label, active }) {
      if (active) {
        return (
          <div className="custom-tooltip">
            <span className="label">
              Date: {Moment(label).format('D.M.YYYY')}
              <br />
              Weight: {payload[0].value} KG
            </span>
          </div>
        )
      }
      return null
    }

    if (this.state.isLoading) {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          <PreloaderComponent />
        </BaseBlockComponent>
      )
    } else {
      const renderDate = payload => {
        return Moment(payload).format('D.M.')
      }

      const renderWeight = payload => {
        let string = payload + ' KG'
        return string
      }

      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          {this.state.message ? (
            <Alert variant={this.state.success ? 'success' : 'danger'}>{this.state.message}</Alert>
          ) : null}
          <div style={{height: '100%'}}>
            <p>
              Latest weight at{' '}
              {Moment(this.state.weightHistory[this.state.weightHistory.length - 1].date).format('D.M.YYYY')}:{' '}
              <b className="green">{this.state.weightHistory[this.state.weightHistory.length - 1].weight} KG</b>
            </p>
            <ResponsiveContainer width="100%" height="30%">
              <LineChart width={400} height={200} data={this.state.weightHistory}>
                <Line type="monotone" dataKey="weight" stroke={styles.pink} />
                <CartesianGrid stroke={styles.comment} />
                <XAxis dataKey="date" stroke={styles.purple} tickFormatter={renderDate} />
                <YAxis
                  domain={['dataMin', 'dataMax']}
                  interval="preserveStartEnd"
                  stroke={styles.purple}
                  tickFormatter={renderWeight}
                />
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
            <Form>
              <Form.Group>
                <Row>
                  <Col xs={2}>
                    <Form.Label>Weight</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      name="weight"
                      id="weight"
                      type="number"
                      defaultValue={this.state.weight}
                      step="0.1"
                      required
                      onChange={handleInputChange}
                      className="medium-form"
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col xs={2}>
                    <Form.Label>Date</Form.Label>
                  </Col>
                  <Col>
                    <DatePicker
                      selected={this.state.date ? new Date(this.state.date) : null}
                      onChange={handleDateChange}
                      dateFormat="dd.MM.yyyy"
                      className="form-control medium-form"
                      locale="fi"
                      popperPlacement="top"
                      popperClassName="calendar-popout"
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>
              <div className="text-center">
                {this.state.addLoading ? <PreloaderComponent /> : <Button onClick={handleAddWeight}>Add</Button>}
              </div>
            </Form>
          </div>
        </BaseBlockComponent>
      )
    }
  }

  async componentDidMount() {
    try {
      let weightData = await api.get('/dashboard/weight', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
      })
      weightData = weightData.data
      const currentWeight = weightData.weightHistory[weightData.weightHistory.length - 1].weight
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          isAuth: false,
          success: weightData.success,
          message: weightData.message,
          weight: currentWeight,
          weightHistory: weightData.weightHistory,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default WeightComponent
