import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PreloaderComponent from '../../common/preloaderComponent'
import BaseBlockComponent from './baseBlockComponent'

import { getCookie } from '../../../utils/cookies'
import api from '../../../utils/api'
import { links } from '../../../config'

class EmailComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      id: getCookie('id'),
      success: true,
      message: null,
      gmail: null,
      utu: null,
      lvs: null,
    }

    this.icon = 'envelope'
    this.color = 'red'
    this.title = 'Email'
    this.link = links.email
  }

  render() {
    function AccountComponent(props) {
      return (
        <div className="email-account">
          <ul style={{ listStyleType: 'none' }}>
            {props.emails.map((email, i) => (
              <li key={i}>
                <FontAwesomeIcon icon="envelope" className="pink" /> <span className="purple">{email.from}:</span>{' '}
                {email.subject}
              </li>
            ))}
          </ul>
        </div>
      )
    }
    if (this.state.isLoading) {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          <PreloaderComponent />
        </BaseBlockComponent>
      )
    } else {
      return (
        <BaseBlockComponent icon={this.icon} color={this.color} title={this.title} link={this.link}>
          {!this.state.success && this.state.message ? (
            <Alert variant="danger">{this.state.message}</Alert>
          ) : (
            <div style={{ height: '100%' }}>
              {!this.state.gmail.success && this.state.gmail.message ? (
                <Alert variant="danger">{this.state.gmail.message}</Alert>
              ) : (
                <div>
                  <div className="mini-header">
                    <FontAwesomeIcon icon="inbox" />{' '}
                    <a href={links.email} target="_blank" rel="noopener noreferrer">
                      GMail
                    </a>
                  </div>
                  <AccountComponent emails={this.state.gmail.mails} />
                </div>
              )}
              {!this.state.utu.success && this.state.utu.message ? (
                <Alert variant="danger">{this.state.utu.message}</Alert>
              ) : (
                <div>
                  <div className="mini-header">
                    <FontAwesomeIcon icon="inbox" />{' '}
                    <a href={links.utuMail} target="_blank" rel="noopener noreferrer">
                      UTU
                    </a>
                  </div>
                  <AccountComponent emails={this.state.utu.mails} />
                </div>
              )}
              {!this.state.lvs.success && this.state.lvs.message ? (
                <Alert variant="danger">{this.state.lvs.message}</Alert>
              ) : (
                <div>
                  <div className="mini-header">
                    <FontAwesomeIcon icon="inbox" />{' '}
                    <a href={links.lvsMail} target="_blank" rel="noopener noreferrer">
                      LVS
                    </a>
                  </div>
                  <AccountComponent emails={this.state.lvs.mails} />
                </div>
              )}
            </div>
          )}
        </BaseBlockComponent>
      )
    }
  }

  async componentDidMount() {
    try {
      let emailData = await api.get('/dashboard/email', {
        headers: {
          Authorization: getCookie('dashboard-token'),
          'Content-Type': 'application/json',
        },
      })

      emailData = emailData.data

      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: emailData.success,
          message: emailData.message,
          gmail: emailData.gmail,
          utu: emailData.utu,
          lvs: emailData.lvs,
        },
      })
    } catch (e) {
      this.setState({
        ...this.state,
        ...{
          isLoading: false,
          success: false,
          message: 'Request failed',
        },
      })
    }
  }
}

export default EmailComponent
