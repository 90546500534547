const apiUrl = process.env.REACT_APP_API_URL

const links = {
  clock: 'https://time.is',
  bank: 'https://danskebank.fi/sinulle/asiakaspalvelu?n-login=pbebank',
  calendar: 'https://calendar.google.com/calendar/r',
  weight: '/',
  weather: 'https://www.ilmatieteenlaitos.fi/saa/turku',
  bitcoin: 'https://coinmarketcap.com/currencies/bitcoin/',
  notes: 'customdir://home/mjt/cloud/notes',
  email: 'https://gmail.com',
  utuMail: 'https://mail.utu.fi',
  lvsMail: 'https://mail.google.com/mail/u/1/',
  github: 'https://github.com/mjturt',
  gitlab: 'https://gitlab.com/mjturt',
  wikipedia: 'https://fi.wikipedia.org',
  hackernews: 'https://news.ycombinator.com',
  sysinfo: 'https://turtia.org',
  yle: 'https://yle.fi/',
}

module.exports = {
  apiUrl,
  links,
}
