import React, { Component } from 'react'
import { Spinner } from 'react-bootstrap'

class PreloaderComponent extends Component {
  render() {
    if (this.props.size === 'big') {
      return (
        <div style={{ left: '50%', top: '50%', position: 'absolute', transform: 'translate(-50%, -50%)'}}>
          <Spinner animation="grow" style={{ height: '100px', width: '100px' }} />
        </div>
      )
    }
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    )
  }
}

export default PreloaderComponent
