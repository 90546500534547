import React, { Component } from 'react'

import { Redirect } from 'react-router-dom'
import { getCookie, setCookie } from '../../utils/cookies'

class LogoutComponent extends Component {
  render() {
    setCookie('dashboard-token', getCookie('token'), 0)
    setCookie('id', getCookie('id'), 0)

    if (getCookie('dashboard-token') === null || getCookie('dashboard-token') === '') {
      return <Redirect to="/login" />
    }

    return <Redirect to="/" />
  }
}

export default LogoutComponent
